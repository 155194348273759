<template>
    <el-dialog title="备款记录" :visible.sync="detailsDialog" custom-class='portStatistics-detailsDialog class-dialog' width="520px" @close="onCancel">
		<div class="dialog-inside Configurentedialog">
			<el-table :data="FinancialLogList" style="width: 100%">
				<el-table-column prop="created_at" label="端口名称"> </el-table-column>
				<el-table-column prop="for_the_amount" label="增加金额">
					<template slot-scope="scope">
						<p>{{ThousandSeparator(scope.row.for_the_amount)}}</p>
					</template>
				</el-table-column>
			</el-table>
            <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="ongetTopFinancialLogList" background layout="prev, pager, next" :total="lvtotal" hide-on-single-page></el-pagination>
		</div>
	</el-dialog>
</template>

<script>
export default {
	data(){
        return{
			detailsData:{},//接收数据
			FinancialLogList:[],
            detailsDialog:false,
			per_page: 10, //一页多少条
            lvtotal: null, //共几条数据
        }
    },
	mounted(){
	},
    methods:{
		//打开弹窗
		ondetailsDialog(data){
			this.detailsData = data
			this.ongetTopFinancialLogList()
			this.detailsDialog = true
		},
		ongetTopFinancialLogList(val){
			var param = {
                page: val,
				financial_id:this.detailsData.id
			}
			this.$service.get(this.$api.getTopFinancialLogList,param, (res)=> {
				if(res.code == '200'){
					this.FinancialLogList = res.data.data
					this.lvtotal = res.data.total
				}
			})
		},
		//弹窗关闭
		onCancel(){

		}
    },

	

	// methods: {
		// 测试用
		// onceshi(){
		// 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
		// 		console.log(123)
		// 		if(res.code == '200'){

		// 		}
		// 	})
		// },
	// }
}
</script>

<style lang="scss" scoped>

</style>
<style lang="scss">
.portStatistics-detailsDialog{
	min-height: 300px;
}
.modifpopper{
	.content{
		padding: 12px 20px;
		padding-bottom: 0;
		text-align: center;
		p{
			margin-bottom: 10px;
			.el-icon-edit{
			}
			.el-input{
				input{
					text-align: center !important;
				}
			}
		}
	}
	.el-button{
		margin-left: 0;
	}
}
</style>