<template>
    <div class="portStatistics">
        <div class="padd20 borbut20">
            <div class="topSearchSou">
                <div class="searchdan">
                    <div class="title">端口名称：</div>
                    <PortSearch ref="PortSearch"></PortSearch>
                </div>
                <el-button type="primary" icon="el-icon-search" @click="onButtonInquire()">搜索</el-button>
                <el-button class="Shuarefresh" @click="$router.go(0)" icon='el-icon-refresh-right'>重置数据</el-button>
            </div>
        </div>
        <div class="group-table padd20">
            <div class="duoshao">
                <div class="title">端口统计</div>
                <!-- <el-button type="primary" class="xinqiye" icon="el-icon-plus" @click="onNewGroup('')">新增群</el-button> -->
                <div class="lvtotal">总条数：{{lvtotal}} 条</div>
            </div>
            <el-table :data="FinancialList" style="width: 100%">
                <el-table-column prop="date" label="日期"> </el-table-column>
                <el-table-column prop="financial_advertiser.advertiser_full_name" label="端口名称"> </el-table-column>
                <el-table-column prop="beginning_balance" label="期初余额">
                    <template slot-scope="scope">
                        <p>{{ThousandSeparator(scope.row.beginning_balance)}}</p>
                        <p style="color:#999;font-size:12px">{{smallToBig(scope.row.beginning_balance)}}</p>
                    </template>
                </el-table-column>
                <el-table-column prop="for_the_amount" label="备款金额（元）">
                    <template slot-scope="scope">
                        <p>{{ThousandSeparator(scope.row.for_the_amount)}}
                            <el-popover placement="bottom" width="300" popper-class="modifpopper" v-if="dateTime == scope.row.date" @hide="onPopoverDide">
                                <div class="content">
                                    <p>增加备款金额</p>
                                    <p>
                                        <el-input v-model="for_the_amount" :placeholder="scope.row.for_the_amount" oninput="value=value.replace(/[^\d\.]/g,'')"></el-input>
                                    </p>
                                </div>
                                <div style="text-align: center; margin: 0">
                                    <el-button type="primary" size="mini" @click="onReference(scope.row)" round>保存修改</el-button>
                                </div>
                                <i slot="reference" class="el-icon-edit" style="cursor: pointer;color:#0075f7"></i>
                            </el-popover>
                        </p>
                        <p style="color:#999;font-size:12px">{{smallToBig(scope.row.for_the_amount)}}</p>
                    </template>
                </el-table-column>
                <el-table-column prop="today_order_actual_balance" label="充值金额">
                    <template slot-scope="scope">
                        <p>{{ThousandSeparator(scope.row.today_order_balance)}}</p>
                        <p style="color:#999;font-size:12px">{{smallToBig(scope.row.today_order_balance)}}</p>
                    </template>
                </el-table-column>
                <el-table-column prop="today_order_actual_balance" label="充值广告币（元）">
                    <template slot-scope="scope">
                        <p>{{ThousandSeparator(scope.row.today_order_actual_balance)}}</p>
                        <p style="color:#999;font-size:12px">{{smallToBig(scope.row.today_order_actual_balance)}}</p>
                    </template>
                </el-table-column>
                <el-table-column prop="your_kind_balance" label="备款余额">
                    <template slot-scope="scope">
                        <p>{{ThousandSeparator(scope.row.your_kind_balance)}}</p>
                        <p style="color:#999;font-size:12px">{{smallToBig(scope.row.your_kind_balance)}}</p>
                    </template>
                </el-table-column>
                <el-table-column prop="your_kind_balance" label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" @click="onDetails(scope.row)">备款记录</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="DialogCurrentChange" background layout="prev, pager, next" :total="lvtotal" hide-on-single-page></el-pagination>
        </div>
        <!-- 新增群 -->
        <DetailsDialog ref="DetailsDialog"></DetailsDialog>
    </div>
</template>

<script>
import DetailsDialog from "./detailsDialog.vue";
import PortSearch from "@/views/commonSearch/portSearch.vue"; //端口选择框
export default {
    data() {
        return {
            paramData:{},//表格参数
            company_name: "",
            FinancialList: [], //群列表
            per_page: 10, //一页多少条
            lvtotal: null, //共几条数据
            paramPage:1,//表格当前第几页

            dateTime: this.dateFtt("yyyy-MM-dd", new Date()), //今天日期
            for_the_amount: "", //修改备款金额input
            account_port_id: "",
        };
    },
    components: {
        DetailsDialog,
        PortSearch,
    },

    mounted() {
        this.ongetTopFinancialList();
    },
    methods: {
        //搜索
        onButtonInquire(){
            this.paramData = {
                advertiser_id: this.account_port_id,
            }
            this.paramPage = 1
            this.ongetTopFinancialList()
        },
        //端口统计
        ongetTopFinancialList() {
            this.FinancialList = [];
            var param = this.paramData
            param.page = this.paramPage
            this.$service.get(this.$api.getTopFinancialList, param, (res) => {
                if (res.code == "200") {
                    this.FinancialList = res.data.data;
                    this.lvtotal = res.data.total;
                }
            });
        },
        // 分页
        DialogCurrentChange(val) {
            this.paramPage = val
            this.ongetTopFinancialList()
        },
        //清空搜索掉接口 (弹窗回调专用)
        ongetTop() {
            this.company_name = "";
            this.ongetTopFinancialList(1);
        },
        //关闭增加时触发
        onPopoverDide() {
            this.for_the_amount = ""; //修改备款金额input
        },
        //弹窗详情
        onDetails(data) {
            // console.log(data);
            this.$refs.DetailsDialog.ondetailsDialog(data);
        },
        //端口id接收参数****用端口名称必须用这个接收参数
        onPropChange(data) {
            this.account_port_id = data.id;
        },
        onReference(row) {
            var param = {
                financial_id: row.id,
                for_the_amount: this.for_the_amount,
            };
            // console.log(param);
            this.$service.post(
                this.$api.updateTopFinancialForTheAmount,
                param,
                (res) => {
                    if (res.code == "200") {
                        this.$message({
                            message: "增加备款金额成功",
                            type: "success",
                        });
                        this.ongetTopFinancialList();
                        this.for_the_amount = "";
                    }
                }
            );
        },
    },

    // methods: {
    // 测试用
    // onceshi(){
    // 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
    // 		console.log(123)
    // 		if(res.code == '200'){

    // 		}
    // 	})
    // },
    // }
};
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
</style>