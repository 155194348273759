<template>
    <!-- 端口名称 -->
    <el-select v-model="portData" placeholder="请输入端口名" value-key="id" filterable remote clearable @change="onPropChange" reserve-keyword :remote-method="onRemoteKey">
        <div v-infinite-scroll="onScroll" style="" class="flock-load">
            <el-option v-for="(item,key) in AdvertiserList" :key="key" :label="item.advertiser_full_name" :value="item">
            </el-option>
        </div>
    </el-select>
</template>

<script>
export default {
    data() {
        return {
            portData: {},
            operPage: 1, //分页
            AdvertiserList: [], //端口数据
        };
    },
    mounted() {
        this.ongetAdvertiserList();
    },
    methods: {
        //文字改变触发
        onPropChange() {
            this.$parent.onPropChange(this.portData);
        },
        //远程搜索触发
        onRemoteKey() {
            console.log('远程搜索触发','暂未搜索先保留')
        },
        //接口调用
        ongetAdvertiserList() {
            var param = {
                page: this.operPage,
            };
            this.$service.get(this.$api.getAdvertiserList, param, (res) => {
                if (res.code == "200") {
                    // this.AdvertiserList = res.data.data
                    if (
                        res.data.data.length == 10 ||
                        res.data.data.length > 0
                    ) {
                        var AdvertiserList = this.AdvertiserList;
                        res.data.data.forEach((v, k) => {
                            AdvertiserList.push(v);
                        });
                        this.AdvertiserList = AdvertiserList;
                    } else {
                        this.operPage--;
                    }
                }
            });
        },
        // 滚动触发
        onScroll() {
            this.operPage++;
            if (!this.portData) {
                this.ongetAdvertiserList();
            }
        },
    },

    // methods: {
    // 测试用
    // onceshi(){
    // 	this.$service.get(this.$api.getAdvertiserList,'', headers, (res)=> {
    // 		console.log(123)
    // 		if(res.code == '200'){

    // 		}
    // 	})
    // },
    // }
};
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
</style>